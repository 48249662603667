<div >
    <div style="margin-top: 50px;font-size: 20px;margin-bottom: 15px;" fxLayoutAlign="center center">
        {{item.customField17}} &#160;&#160;
        <mat-slide-toggle [(ngModel)]="isChecked" (change)="toggleChanged()" color="primary">{{item.customField18}}</mat-slide-toggle>
    </div>

    <div fxLayout="column" fxLayout.gt-sm="row" style="text-align: center;" fxLayoutGap="15px"
        *ngFor="let data of pricedetailnew">
        <div contentAnim [aniName]="item.customField36" fxFlex="100" fxFlex.gt-sm="33" style="border: 1px solid gray;border-radius: 20px"
        [style.background-color]="isSelected(data.s_price_annualy) ? data.a_button : 'transparent'"
            (click)="selectSubscription(data.s_price_annualy , data.title1, item.customField23, item.customField26)"
            [ngClass]="{'white-font': isSelected(data.s_price_annualy)}">
            <h3><b>{{data.title1}}</b></h3>
            <h4><b>{{data.s_subtitle}}</b></h4>

            <div *ngFor="let subdata of data.list_detail" style="margin-bottom: 10px;">
                <div class="pricelist1" *ngIf="subdata.starters">
                    <div fxLayout="row" fxLayoutAlign="start start">
                        <div>
                            <div class="circleiconz">
                                <mat-icon class="maticon_3">check_circle</mat-icon>
                            </div>
                        </div>

                        <div>
                            <h6  [ngClass]="{'white-font': isSelected(data.s_price_annualy)}"
                                style="margin-bottom: 0px;padding-left: 10px;">
                                &#160;{{subdata.starters}}</h6>
                        </div>
                    </div>
                </div>
            </div>
            <h4><b>₹ {{ isChecked ? (data.s_price_annualy * item.customField29) : (data.s_price_annualy*item.customField35) }}</b></h4>
            <h6 [ngClass]="{'white-font': isSelected(data.s_price_annualy)}">{{data.s_charge_year}}</h6>

            <div>
                <h4 [ngClass]="{'white-font': isSelected(data.s_price_annualy)}">{{item.customField19}}</h4>
                <h4><b>{{item.customField23}}</b></h4>
            </div>

            <div>
                <h4 [ngClass]="{'white-font': isSelected(data.s_price_annualy)}">{{item.customField20}}</h4>
                <h4><b>{{item.customField26}}</b></h4>
            </div>

        </div>

        <div contentAnim [aniName]="item.customField37" fxFlex="100" fxFlex.gt-sm="33"
        [style.background-color]="isSelected(data.p_price_annualy) ? data.a_button : 'transparent'"

            style="border: 1px solid gray;border-radius: 20px;"
            (click)="selectSubscription(data.p_price_annualy , data.title2,item.customField24, item.customField27)"
            [ngClass]="{'white-font': isSelected(data.p_price_annualy)}"
           >
            <h3><b>{{data.title2}}</b></h3>
            <h4><b>{{data.p_subtitle}}</b></h4>

            <div *ngFor="let subdatas of data.list_detail" style="margin-bottom: 10px;">
                <div class="pricelist1" *ngIf="subdatas.professions">

                    <div fxLayout="row" fxLayoutAlign="start start">
                        <div>
                            <div class="circleiconz">
                                <mat-icon class="maticon_3">check_circle</mat-icon>
                            </div>
                        </div>
                        <div>
                            <h6  [ngClass]="{'white-font': isSelected(data.p_price_annualy)}"
                                style="margin-bottom: 0px;padding-left: 10px;">
                                &#160;{{subdatas.professions}}</h6>
                        </div>
                    </div>
                </div>
            </div>
            <h4><b>₹ {{ isChecked ? (data.p_price_annualy * item.customField29) : (data.p_price_annualy*item?.customField35) }}</b></h4>
            <h6  [ngClass]="{'white-font': isSelected(data.p_price_annualy)}">{{data.p_charge_year}}</h6>

            <div>
                <h4  [ngClass]="{'white-font': isSelected(data.p_price_annualy)}">{{item.customField19}}</h4>
                <h4><b>{{item.customField24}}</b></h4>
            </div>

            <div>
                <h4  [ngClass]="{'white-font': isSelected(data.p_price_annualy)}">{{item.customField20}}</h4>
                <h4><b>{{item.customField27}}</b></h4>
            </div>
        </div>

        <div contentAnim [aniName]="item.customField38" fxFlex="100" fxFlex.gt-sm="33" style="border: 1px solid gray;border-radius: 20px"
            (click)="selectSubscription(data.a_price_annualy , data.title3, item.customField25, item.customField28)"
            [ngClass]="{'white-font': isSelected(data.a_price_annualy)}"
            [style.background-color]="isSelected(data.a_price_annualy) ? data.a_button : 'transparent'"
>
            <h3><b>{{data.title3}}</b></h3>
            <h4><b>{{data.a_subtitle}}</b></h4>

            <div *ngFor="let subdataz of data.list_detail" style="margin-bottom: 10px;">
                <div class="pricelist1" *ngIf="subdataz.advanceds">
                    <div fxLayout="row" fxLayoutAlign="start start">
                        <div class="circleiconz">
                            <mat-icon class="maticon_3">check_circle</mat-icon>
                        </div>
                        <div>
                            <h6  [ngClass]="{'white-font': isSelected(data.a_price_annualy)}"
                                style="margin-bottom: 0px;padding-left: 10px;">
                                &#160;{{subdataz.advanceds}}</h6>
                        </div>

                    </div>

                </div>
            </div>
            <h4><b>₹{{ isChecked ? (data.a_price_annualy * item.customField29) : (data.a_price_annualy*item.customField35) }}</b></h4>

            <h6  [ngClass]="{'white-font': isSelected(data.a_price_annualy)}">{{data.a_charge_year}}</h6>


            <div>
                <h4  [ngClass]="{'white-font': isSelected(data.a_price_annualy)}">{{item.customField19}}</h4>
                <h4><b>{{item.customField25}}</b></h4>
            </div>

            <div>
                <h4  [ngClass]="{'white-font': isSelected(data.a_price_annualy)}">{{item.customField20}}</h4>
                <h4><b>  {{item.customField28}}</b></h4>
            </div>
        </div>
    </div>
</div>

