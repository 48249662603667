<div fxHide.lt-md>
  <div fxFlex="30" class="calsoft-login-container" style="right: 78px;width: 30%;" fxLayout="row" >
   <!-- Adjust width as needed -->
<div *ngIf="!loginned">
  <calsoft-common-login [goldform]="0" style="width: 100%;" ></calsoft-common-login>
</div>
<div *ngIf="loginned" class="newlogin-container">
  <div class="user-profile-card">
    <div class="profile-header">
      <div class="profile-icon">
        <mat-icon class="user-icon">account_circle</mat-icon>
      </div>
      <div class="status-indicator"></div>
    </div>
    <div class="profile-info">
      <h2 class="user-name">{{userName}}</h2>
      <p class="user-email">{{userEmail}}</p>
    </div>
    <div class="profile-decoration"></div>
  </div>
  
    
    
 
  </div>
  <div  class="carousel-container">
    <owl-carousel-o [options]="customOptions">
      <ng-container *ngFor="let img of item.images; let i = index">
        <ng-template carouselSlide>
          <div fxLayout="row"  fxLayout.xs="column" style="width: 100%; height:500px;">
            <div fxFlex>
              <img [src]="serverPath+img.img"alt="carousel image" class="carousel-image">
            </div>
            
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
    
  </div>

</div>

<div fxHide fxShow.lt-md>
  <div fxLayout="column" style="background-color: #f7efef;;">
    <div fxFlex class="calsoft-login-container" >
      <!-- Adjust width as needed -->
       <calsoft-common-login   [goldform]="0" ></calsoft-common-login>
       
    
     </div>
     <div  class="carousel-container">
       <owl-carousel-o [options]="customOptions">
         <ng-container *ngFor="let image of images">
           <ng-template carouselSlide>
             <div fxLayout="row"  fxLayout.xs="column" style="width: 100%; height:500px;">
               <div fxFlex>
                 <img [src]="image" alt="carousel image" class="carousel-image">
               </div>
               
             </div>
           </ng-template>
         </ng-container>
       </owl-carousel-o>
       
     </div>
  </div>
  

</div>

  
